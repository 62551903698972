import React from "react";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";
import { isAuthenticated } from "../auth/helper";
import Base from "./Base";
import axios from "axios";
import { buySubs } from "./helper/buySubscription";
import { Link, useHistory } from "react-router-dom";

function Plan() {
  const { user, token } = isAuthenticated();
  const history = useHistory();
  // const handleClick = async (planuid) => {
  //   const data = await buySubs(token, planuid);
  //   const { subscription } = data;
  //   console.log(subscription);
  //   const options = {
  //     key: process.env.REACT_APP_RAZORPAY_KEY,
  //     subscription_id: subscription.id,
  //     name: "Indhan Bazaar",
  //     description: "Quaterly Plan",
  //     image: "https://4.imimg.com/data4/GA/DU/MY-18935415/1-500x500.jpeg",
  //     callback_url: "http://localhost:8080/api/verifypayment",
  //     prefill: {
  //       name: "Lakshy Test1",
  //       email: "test1@mail.com",
  //       contact: "+919650156982",
  //     },
  //     notes: {
  //       note_key_1: "Tea. Earl Grey. Hot",
  //       note_key_2: "Make it so.",
  //     },
  //     theme: {
  //       color: "#F37254",
  //     },
  //   };
  //   const razor = new window.Razorpay(options);
  //   razor.open();
  // };

  const handleClick = async (planuid) => {
    if (token == null && user == null) {
      alert("Login is required");
    } else {
      const data = await buySubs(token, planuid);
      console.log(data);
      if (data.status !== null && data.status == true) {
        alert("Subscription is already active for you");
      } else {
        const { url } = data;
        console.log(url);
        window.location.href = url;
        // const options = {
        //   key: process.env.REACT_APP_RAZORPAY_KEY,
        //   amount: order.amount,
        //   name: "Indhan Bazaar",
        //   order_id: order.id,
        //   description: "Quaterly Plan",
        //   image: "https://4.imimg.com/data4/GA/DU/MY-18935415/1-500x500.jpeg",
        //   // callback_url: "http://localhost:8080/api/verifypayment",
        //   handler: function (response) {
        //     console.log(response);
        //     var values = {
        //       razorpay_order_id: response.razorpay_order_id,
        //       razorpay_payment_id: response.razorpay_payment_id,
        //       razorpay_signature: response.razorpay_signature,
        //       transactionamount: order.amount,
        //       plan: data.plan,
        //     };
        //     axios
        //       .post("http://localhost:8080/api/verifypayment", values)
        //       .then((res) => {
        //         console.log("Success");
        //       })
        //       .catch((e) => console.log(e));
        //   },
        //   prefill: {
        //     name: user.name,
        //     email: user.email,
        //     contact: "9650156982",
        //   },
        //   notes: {
        //     user_id: user._id,
        //   },
        //   theme: {
        //     color: "#F37254",
        //   },
        // };
        // const razor = new window.Razorpay(options);
        // razor.open();
      }
    }
  };

  const handleClick2 = (planid) => {
    history.push({ pathname: "/checkout", state: { planname: planid } });
  };

  return (
    <>
      <Base title="Plan" description="Plans">
        <PricingTable highlightColor="#1976D2">
          <PricingSlot
            onClick={() => {
              handleClick2("POP3");
            }}
            buttonText="Pay Now"
            title="State Prime"
            priceText="₹ 3,499 / 3 month"
          >
            <PricingDetail>
              {" "}
              <b>State wide</b> search access
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>5 </b> unlocks/day
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>Unlimited</b> leads
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>Time tracking</b>
            </PricingDetail>
          </PricingSlot>
          <PricingSlot
            highlighted
            onClick={() => {
              handleClick("POP2");
            }}
            buttonText="Pay Now"
            title="Nation Prime"
            priceText="₹ 7,499 / 3 month"
          >
            <PricingDetail>
              {" "}
              <b>Nation wide</b> search access
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>5 </b> unlocks/day
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>Unlimited</b> leads
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>Time tracking</b>
            </PricingDetail>
          </PricingSlot>
          <PricingSlot
            onClick={() => {
              handleClick("POP1");
            }}
            buttonText="Pay Now"
            title="ENTERPRISE"
            priceText="₹ 12,599 / year"
          >
            <PricingDetail>
              {" "}
              <b>Affordable</b>
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>Nation wide</b> search access
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>5 </b> unlocks/day
            </PricingDetail>
            <PricingDetail>
              {" "}
              <b>Unlimited leads</b>
            </PricingDetail>
          </PricingSlot>
        </PricingTable>
      </Base>
    </>
  );
}

export default Plan;
