import React, { useState, useEffect } from "react";
import "../styles.css";
import { API } from "../backend";
import Base from "./Base";
import Card from "./Card";
import { getProducts, getCategories } from "./helper/coreapicalls";
import { Carousel, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import image4 from "../images/image4.jpg";
import image5 from "../images/image5.jpg";
import image6 from "../images/image 6.jpg";
import Search from "./search";

export default function Home() {
  return (
    <>
      {/* <Carousel>
        <Carousel.Item interval={1500}>
          <img className="d-block w-100" src={image5} alt="First slide" height="650px" />
          <Carousel.Caption className="d-flex justify-content-center">
            <h3 style={{ color: "#FF6666", fontSize: "70px" }} className="bg-white">
              Sweatshirts
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img className="d-block w-100" src={image3} alt="Second slide" height="650px" />
          <Carousel.Caption className="d-flex justify-content-center">
            <h3 style={{ color: "#120E43", fontSize: "70px" }} className="bg-warning">
              Wireless Headphones
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img className="d-block w-100" src={image6} alt="Third slide" height="650px" />
          <Carousel.Caption className="d-flex justify-content-center">
            <h3 style={{ color: "#B4161B", fontSize: "70px" }} className="bg-info">
              T-Shirts
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}
      <Base title="Welcome to Ecommerce Store" description="Purchase products here">
        <div className="row text-center d-flex justify-content-center">
          <div className="col-12">
            <h1
              style={{
                color: "black",
                marginBottom: "3%",
                fontFamily: "cursive",
                fontSize: "50px",
              }}
            >
              Indhan Bazaar
            </h1>
          </div>
          <Search />
        </div>
      </Base>
    </>
  );
}
