import React from "react";
import Base from "./Base";

function Success() {
  return (
    <>
      <Base title="Plan" description="Plans">
        <h1 style={{ color: "black" }}>Your Payment is successful</h1>
      </Base>
    </>
  );
}

export default Success;
