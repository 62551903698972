import React from "react";
import Base from "./Base";

function Failure() {
  return (
    <>
      <Base title="Plan" description="Plans">
        <h1 style={{ color: "black" }}>
          Your Payment has been failed! <br />
          <br /> You will get refund in your original payment method within 3-5 business days
        </h1>
      </Base>
    </>
  );
}

export default Failure;
