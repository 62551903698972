import React, { useEffect, useState } from "react";
import { isAuthenticated } from "../auth/helper";
import Base from "./Base";
import { getUser, unlockUser } from "./helper/viewUserHelper";

const ViewUser = ({ match }) => {
  const { user, token } = isAuthenticated();
  const [values, setValues] = useState({
    name: "",
    email: "",
    state: "",
    city: "",
    town: "",
    pincode: "",
    role: "",
    error: "",
  });

  const { name, email, state, city, town, pincode, role, error } = values;

  const preload = (userId) => {
    getUser(userId, token).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        console.log(data);
        setValues({
          ...values,
          name: data.name,
          email: data.email,
          state: data.address.state,
          city: data.address.city,
          town: data.address.town,
          pincode: data.address.pincode,
          role: data.role,
        });
      }
    });
  };

  useEffect(() => {
    preload(match.params.userId);
  }, []);

  const handleSubmit = () => {
    unlockUser(user._id, match.params.userId, token).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        console.log(data);
        if (data.message != null) {
          alert(data.message);
        } else {
          setValues({
            ...values,
            email: data.email,
            pincode: data.pincode,
          });
        }
      }
    });
  };

  return (
    <Base title="View User" description="View User" className="container bg-dark p-4 mt-5 mb-5">
      <div className="container m-auto mt-5 d-flex align-items-center flex-column">
        {/* <div className="row text-dark">
          Name:
          <label className="font-weight-bold text-center h4 pl-4">{name}</label>
        </div>
        <div className="row text-dark">
          Email: <label className="font-weight-bold text-center h4 pl-4">{email}</label>
        </div>
        <div className="row  text-dark">
          Town: <label className="font-weight-bold text-center h4 pl-4">{town}</label>
        </div>
        <div className="row text-dark">
          City: <label className="font-weight-bold text-center h4 pl-4">{city}</label>
        </div>
        <div className="row text-dark">
          State: <label className="font-weight-bold text-center h4 pl-4">{state}</label>
        </div>
        <div className="row text-dark">
          Pincode: <label className="font-weight-bold text-center h4 pl-4">{pincode}</label>
        </div>
        <div className="row text-dark">
          Role: <label className="font-weight-bold text-center h4 pl-4">{role}</label>
        </div> */}
        <div className="card mb-4">
          <h4 className="card-header">{name} Info</h4>
          <ul className="list-group">
            <li className="list-group-item">
              <span className="badge badge-danger mr-2">Awesome {role} here</span>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">Name:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{name}</label>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">Email:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{email}</label>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">Role:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{role}</label>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">Town:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{town}</label>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">City:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{city}</label>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">State:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{state}</label>
            </li>
            <li className="list-group-item">
              <span className="badge badge-success mr-2">Pincode:</span>{" "}
              <label className="font-weight-bold text-center h4 pl-4">{pincode}</label>
            </li>
          </ul>
          <div className="mt-4"></div>
          {email === "" ? (
            <button type="submit" className="btn btn-primary searchbtn" onClick={handleSubmit}>
              Unlock 🔓
            </button>
          ) : null}
        </div>
      </div>
    </Base>
  );
};

export default ViewUser;
