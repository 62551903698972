import React, { useEffect, useState } from "react";
import "../styles.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { fetchUsers } from "./helper/searchHelper";
import { isAuthenticated } from "../auth/helper";

export default function Search() {
  const { user, token } = isAuthenticated();

  const handleSubmit = () => {
    console.log(selectedState, selectedCity);
    if (token === null || token === undefined) {
      alert("Login required");
    } else if (selectedState == null || selectedCity == null) {
      alert("Please select state & city to search");
    } else {
      fetchUsers(token, selectedCity.name, selectedState.name)
        .then((data) => {
          console.log(data);
          if (data.success === false) {
            alert(data.message);
          } else {
            setCitydata(data);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const handleChange = (e) => {
    setDrop({ value: e.target.value });
  };

  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [citydata, setCitydata] = useState([]);
  const [drop, setDrop] = useState({ value: "nearme" });

  useEffect(() => {
    console.log(selectedCountry);
    console.log(selectedCountry?.isoCode);
    console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));
  }, [selectedCountry]);
  return (
    <div class="container-fluid">
      <div className="Search row justify-content-center">
        <div className="col-4">
          <Select
            theme={(theme) => ({
              ...theme,
              borderRadius: 3,
              borderColor: "black",
              colors: {
                ...theme.colors,
                primary25: "hotpink",
              },
            })}
            placeholder="Select State"
            options={State?.getStatesOfCountry("IN")}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["name"];
            }}
            value={selectedState}
            onChange={(item) => {
              setSelectedState(item);
            }}
          />
        </div>
        <div className="col-4">
          <Select
            theme={(theme) => ({
              ...theme,
              borderRadius: 3,
              borderColor: "black",
              colors: {
                ...theme.colors,
                primary25: "hotpink",
              },
            })}
            placeholder="Select City"
            options={City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["name"];
            }}
            value={selectedCity}
            onChange={(item) => {
              setSelectedCity(item);
            }}
          />
        </div>
        <div className="col-1">
          <button
            type="submit"
            className={
              token !== null ? "btn btn-info active searchbtn" : "btn btn-info disabled searchbtn"
            }
            onClick={handleSubmit}
          >
            Search
          </button>
        </div>
        <div className="col-3">
          <select id="dropdown" value={`${drop.value}`} onChange={(e) => handleChange(e)}>
            <option value="na" disabled>
              Search Results By
            </option>
            <option value="nearme">Near Me</option>
            <option value="statename">State and City Name</option>
          </select>
        </div>
      </div>

      <div className="row justify-content-center text-center pt-3 mt-5">
        <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"Name"}</h4>
        </div>
        {/* <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"Email"}</h4>
        </div> */}
        <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"City"}</h4>
        </div>
        <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"State"}</h4>
        </div>
        <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"Pincode"}</h4>
        </div>
        <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"Role"}</h4>
        </div>
        <div className="col-2 bg-light border border-secondary rounded">
          <h4 className="text-center font-weight-bold text-dark">{"View"}</h4>
        </div>
      </div>
      <div className="mt-2"></div>
      {citydata.map((userd, index) => {
        return (
          <>
            <div key={index} className="row justify-content-center mb-2">
              <div className="col-2 bg-light border border-secondary rounded">
                <p className="text-center font-weight-bold text-dark">{userd.name}</p>
              </div>
              {/* <div className="col-2 bg-light border border-secondary rounded">
                <p className="text-center font-weight-bold text-dark">{userd.email}</p>
              </div> */}
              <div className="col-2 bg-light border border-secondary rounded">
                <p className="text-center font-weight-bold text-dark">{userd.address.city}</p>
              </div>
              <div className="col-2 bg-light border border-secondary rounded">
                <p className="text-center font-weight-bold text-dark">{userd.address.state}</p>
              </div>
              <div className="col-2 bg-light border border-secondary rounded">
                <p className="text-center font-weight-bold text-dark">{userd.address.pincode}</p>
              </div>
              <div className="col-2 bg-light border border-secondary rounded">
                <p className="text-center font-weight-bold text-dark">{userd.role}</p>
              </div>
              <div className="col-2 bg-light border border-secondary rounded">
                <Link
                  className={userd.isUnlocked === undefined ? "btn btn-danger" : "btn btn-primary"}
                  to={`/user/${userd._id}`}
                >
                  <span className="">
                    {userd.isUnlocked === undefined ? "Locked 🔒" : "View 🔓"}
                  </span>
                </Link>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}
