import React, { useState, useEffect } from "react";
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { signup } from "../auth/helper";
import Select from "react-select";
import { Country, State, City } from "country-state-city";

const Signup = () => {
  const [values, setValues] = useState({
    //defining the state
    name: "",
    email: "",
    password: "",
    streetname: "",
    town: "",
    pincode: "",
    error: "",
    success: false,
  });

  const { name, email, password, error, success, streetname, town, pincode } = values; //destructure the things so as to use them as name, email not as values.name or values.email
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [role, setRole] = useState({ value: "na" });

  const handleChange1 = (e) => {
    setRole({ value: e.target.value });
  };

  const handleChange = (name) => (event) => {
    //ye name , email, password apne aap dkh ke chnage kar dega 3no ke lie seprate function likhne ki jarurat nahi hai
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const onSubmit = (event) => {
    //banda jab form ke submit button pe click karega to kya hoga ye bata ra hai
    event.preventDefault(); //jo by default hota hau use rokk aur ye karle jo likha hai ye batyega function ko
    setValues({ ...values, error: false });
    signup({ name, email, password, streetname, town, selectedCity, selectedState, pincode, role })
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, success: false });
        } else {
          setValues({
            ...values,
            name: "",
            email: "",
            password: "",
            streetname: "",
            town: "",
            pincode: "",
            error: "",
            success: true,
          });
          setSelectedState(null);
          setSelectedCity(null);
          setRole("na");
        }
      })
      .catch((err) => console.log(err));
  };

  const signUpForm = () => {
    return (
      <div className="row mb-3">
        <div className="col-md-6 offset-sm-3 text-left border border-dark rounded " id="border-3">
          <h1 className="mt-3 d-flex justify-content-center" style={{ color: "black" }}>
            Create New Account
          </h1>
          <hr />
          <form>
            <div className="form-group">
              {/* <label className="text-dark">Select Role*</label> */}
              <br />
              <select
                required
                id="dropdown"
                value={`${role.value}`}
                onChange={(e) => handleChange1(e)}
              >
                <option value="na" disabled>
                  Select Your Role
                </option>
                <option value="manufacturer">Manufacturer</option>
                <option value="consumer">Consumer</option>
              </select>
            </div>

            <div className="form-group">
              <label className="text-dark">Name</label>
              <input
                className="form-control"
                onChange={handleChange("name")}
                type="text"
                value={name}
              />
            </div>
            <div className="form-group">
              <label className="text-dark">Email</label>
              <input
                className="form-control"
                onChange={handleChange("email")}
                type="email"
                value={email}
              />
            </div>

            <div className="form-group">
              <label className="text-dark">Password</label>
              <input
                onChange={handleChange("password")}
                className="form-control"
                type="password"
                value={password}
              />
            </div>

            <hr />
            <div className="form-group text-center">
              <h4 className="text-dark">Address</h4>
            </div>
            <div className="form-group">
              <label className="text-dark">Plot Name / Street Name</label>
              <input
                onChange={handleChange("streetname")}
                className="form-control"
                type="plotno"
                value={streetname}
              />
            </div>

            <div className="form-group">
              <label className="text-dark">Locality / Town</label>
              <input
                onChange={handleChange("town")}
                className="form-control"
                type="town"
                value={town}
              />
            </div>
            <div className="form-group">
              <label className="text-dark">State</label>
              {/* <input
                onChange={handleChange("city")}
                className="form-control"
                type="city"
                value={city}
              /> */}
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 3,
                  borderColor: "black",
                  colors: {
                    ...theme.colors,
                    primary25: "hotpink",
                  },
                })}
                placeholder="Select State"
                options={State?.getStatesOfCountry("IN")}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedState}
                onChange={(item) => {
                  setSelectedState(item);
                }}
              />
            </div>

            <div className="form-group">
              <label className="text-dark">City</label>
              {/* <input
                onChange={handleChange("state")}
                className="form-control"
                type="state"
                value={state}
              /> */}
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 3,
                  borderColor: "black",
                  colors: {
                    ...theme.colors,
                    primary25: "hotpink",
                  },
                })}
                placeholder="Select City"
                options={City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedCity}
                onChange={(item) => {
                  setSelectedCity(item);
                  handleChange("city");
                }}
              />
            </div>

            <div className="form-group">
              <label className="text-dark">Pin Code</label>
              <input
                onChange={handleChange("pincode")}
                className="form-control"
                type="pincode"
                value={pincode}
              />
            </div>

            <button
              type="button"
              onClick={onSubmit}
              className="btn btn-primary rounded btn-block mb-3"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  };

  const successMessage = () => {
    return (
      <div className="row pt-5">
        <div className="col-md-6 offset-sm-3 text-left">
          <div className="alert alert-success" style={{ display: success ? "" : "none" }}>
            New account was created successfully. Please <Link to="/signin">Login Here</Link>
          </div>
        </div>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div className="row pt-5">
        <div className="col-md-6 offset-sm-3 text-left">
          <div className="alert alert-danger" style={{ display: error ? "" : "none" }}>
            {error}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Base title="Sign up page" description="A page for user to sign up!">
      {successMessage()}
      {errorMessage()}
      {signUpForm()}
      {/* <p className="text-white text-center">{JSON.stringify(values)}</p> */}
    </Base>
  );
};

export default Signup;

// import React,{useState} from 'react';
// import Base from '../core/Base';
// import {Link} from 'react-router-dom';
// import {signup} from '../auth/helper';

// const Signup = () => {

//     const [values, setValues] = useState({ //defining the state
//         name: "",
//         email: "",
//         password: "",
//         error: "",
//         success : false
//     });

//     const {name,email,password,error,success} = values; //destructure the things so as to use them as name, email not as values.name or values.email

//     const handleChange = name => event => { //ye name , email, password apne aap dkh ke chnage kar dega 3no ke lie seprate function likhne ki jarurat nahi hai
//         setValues({...values, error: false, [name]: event.target.value });
//     };

//     const onSubmit = event => { //banda jab form ke submit button pe click karega to kya hoga ye bata ra hai
//         event.preventDefault(); //jo by default hota hau use rokk aur ye karle jo likha hai ye batyega function ko
//         setValues({...values, error: false});
//         signup({name,email,password})
//         .then(data => {
//             if(data.error){
//                 setValues({...values, error: data.values, success: false});
//             }
//             else{
//                 setValues({
//                     ...values,
//                     name: "",
//                     email: "",
//                     password:"",
//                     error: "",
//                     success: true
//                 });
//             }
//         })
//         .catch(err=>console.log("error in signp",err));//console.log("Error in signup")
//     }

//     const SignupForm = () =>{
//         return(
//             <div className="row">
//                 <div className="col-md-6 offset-sm-3 text-left">
//                     <form>
//                         <div className="form-group">
//                             <label className="text-light">Name</label>
//                             <input className="form-control" onChange={handleChange("name")} type="text" />
//                         </div>
//                         <div className="form-group">
//                             <label className="text-light">Email</label>
//                             <input className="form-control" onChange={handleChange("email")} type="text" />
//                         </div>
//                         <div className="form-group">
//                             <label className="text-light">Password</label>
//                             <input className="form-control" onChange={handleChange("password")} type="password" />
//                         </div>
//                         <button onClick={onSubmit} className="btn btn-success btn-block">Submit</button>
//                     </form>
//                 </div>
//             </div>
//         );
//     };

//     return (
//         <Base title="Signup page" description="A page for user to signup !">
//             {SignupForm()}
//                 <p className="text-white text-center">{JSON.stringify(values)}</p>
//         </Base>
//     )
// }

// export default Signup;
