import React,{useState, useEffect} from 'react'
import Base from '../core/Base';
import { Link } from 'react-router-dom';
import { getCategories, getProduct, updateProduct } from './helper/adminapicall';
import { isAuthenticated } from '../auth/helper';

const UpdateProduct = ({match}) => {

    const {user,token} = isAuthenticated();
    const [values, setValues] = useState({
        name:"",
        description:"",
        price:"",
        stock:"",
        photo:"",
        categories: [],
        category:"",
        loading:false,
        error:"",
        createdProduct:"",
        getaRedirect: false,
        formData: "" 
    });

    const{name,description,price,stock,photo,categories,category,loading,error,createdProduct,getaRedirect,formData} = values

    //preload loads the everything before it loads the page
    const preload = (productId) =>{
        getProduct(productId)
        .then(data => {
            // console.log(data);
            if(data.error){
                setValues({...values, error:data.error});
            }
            else{
                setValues({
                    ...values,
                    name: data.name,
                    description:data.description,
                    price: data.price,
                    category: data.category._id,
                    stock: data.stock,
                    formData: new FormData(),
                });
                preloadCategories();
                // console.log(categories);
            }
        });
    };

    const preloadCategories = () => {
        getCategories()
        .then(data => {
            if(data.error){
                setValues({...values, error:data.error});
            }
            else{
                setValues({
                    categories : data,
                    formData: new FormData(),
                })
            }
        })
    }
    
    useEffect(() => {
        preload(match.params.productId);
    }, [])


    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: "" , loading: true})
        updateProduct(match.params.productId,user._id, token, formData)
        .then(data => {
            if(data.error){
                setValues({...values, error:data.error})
            }
            else{
                setValues({
                    ...values,
                    name:"",
                    description:"",
                    price:"",
                    photo:"",
                    stock:"",
                    loading:false,
                    createdProduct:data.name,
                    
                })
            }
        })
        .catch()
    }

    

    const handleChange = name => event => {
        const value = name === "photo" ? event.target.files[0] : event.target.value;
        formData.set(name,value);
        setValues({...values, [name]:value});
    };

    const successMessage = () => (
        <div className="alert alert-success mt-3" style={{display: createdProduct ? "": "none"}}>
            <h4>{createdProduct} updated Successfully</h4>
        </div>
    )

    const errorMessage = () => (
        <div className="alert alert-warning mt-3" style={{display: error ? "": "none"}}>
            <h4>Try Again! Falied to create new Product</h4>
        </div>
    )
 
    //product form
    const createProductForm = () => (
        <form >
          <span>Upload photo</span>
          <div className="form-group">
            <label className="btn btn-block btn-secondary rounded">
              <input
                onChange={handleChange("photo")}
                type="file"
                name="photo"
                accept="image"
                placeholder="choose a file"
              />
            </label>
          </div>
          <div className="form-group">
            <label>Name: </label>
            <input
              onChange={handleChange("name")}
              name="photo"
              className="form-control"
              placeholder="Name"
              value={name}
            />
          </div>
          <div className="form-group">
            <label>Description: </label>
            <textarea
              onChange={handleChange("description")}
              name="photo"
              className="form-control"
              placeholder="Description"
              value={description}
            />
          </div>
          <div className="form-group">
            <label>Price: </label>
            <input
              onChange={handleChange("price")}
              type="number"
              className="form-control"
              placeholder="Price"
              value={price}
            />
          </div>
          <div className="form-group">
            <label>Category: </label>
            <select
              onChange={handleChange("category")}
              className="form-control"
              placeholder="Category"
            >
              <option>Select</option>
              {categories && 
                categories.map( (cate, index) => (
                <option key={index} value={cate._id}>{cate.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group">
            <label>Stocks: </label>
            <input
              onChange={handleChange("stock")}
              type="number"
              className="form-control"
              placeholder="Quantity"
              value={stock}
            />
          </div>
          
          <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-3">
            Update Product
          </button>
        </form>
      );

    return (
        <Base title="Add product here !" description="Product creation section" className="container bg-secondary p-4">
            <Link to="/admin/dashboard" className="btn btn-small btn-dark mb-3">Admin Home</Link>
            <div className="row bg-white rounded py-3 ml-5 mr-5">
                <div className="col-md-8 offset-md-2">
                    {errorMessage()}
                    {successMessage()}
                    {createProductForm()}
                    
                </div>
            </div>

        </Base>
    )
}
export default UpdateProduct;