import React, { useEffect, useState } from "react";
import Base from "./Base";
import { useLocation, useHistory } from "react-router-dom";
import { getPlan, validateCoupon } from "./helper/checkoutPlan";
import { isAuthenticated } from "../auth/helper";

function PlanCheckout() {
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [price, setPrice] = useState("");
  const [plan, setPlan] = useState([]);
  const { state } = useLocation();
  const { planid } = state;
  const { user, token } = isAuthenticated();

  const handleChange = (event) => {
    setError("");
    setName(event.target.value); //jo bhi value form me hogi voh isme aa jygi
  };

  const successMessage = () => {
    if (success != null) {
      return <h4 className="text-success">{success}</h4>;
    }
  };

  const warningMessage = () => {
    if (error != null) {
      return <h4 className="text-danger">{error}</h4>;
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (name == null) {
      alert("Enter coupon code");
    } else if (price != "") {
      alert("Coupon Already applied");
    } else {
      setError("");
      setSuccess(false);

      validateCoupon(token, plan._id, name)
        .then((data) => {
          console.log(data);
          if (data.error) {
            setError(true);
            setPrice("");
          } else {
            if (data.finalAmount == undefined || data.finalAmount == null) {
              setError(data.message);
              setName(null);
              setPrice("");
            } else {
              setSuccess(data.message);
              setName("");
              setError(null);
              setPrice(data.finalAmount);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const preload = (planId) => {
    getPlan(planId).then((data) => {
      if (data.error) {
        alert(data.error);
      } else {
        console.log(data);
        setPlan(data);
      }
    });
  };

  useEffect(() => {
    preload(planid);
  }, []);

  const myCouponForm = () => (
    <form>
      <div className="form-group">
        {/* <p className="lead font-weight-normal">Coupon Code</p> */}
        <input
          type="text"
          className="form-control my-3"
          onChange={handleChange}
          value={name}
          autoFocus
          required
          placeholder="eg. NEW2023"
        />
        <button onClick={onSubmit} className="btn btn-dark">
          {" "}
          Apply Coupon
        </button>
      </div>
    </form>
  );

  return (
    <>
      <Base title="Checkout" description="Checkout">
        <div class="container-fluid">
          <div className="Search row justify-content-center">
            <div className="col-12 text-dark">
              <h1>{plan.plan_name}</h1>
              <h2>Plan Price : {`₹ ${plan.price}`}</h2>
              <h2>Duration : {plan.duration} months</h2>
            </div>
            <div className="col-3 text-dark">{myCouponForm()}</div>
            <div className="col-12 text-dark">{successMessage()}</div>
            <div className="col-12 text-dark">{warningMessage()}</div>

            <div className="col-12 text-dark">
              <p className="text-primary h2">
                Final Amount <p className="h5">(to be paid)</p> {`₹ ${price}`}
              </p>
            </div>
          </div>
          <div className="row justify-content-center"></div>
        </div>
      </Base>
    </>
  );
}

export default PlanCheckout;
