import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../auth/helper";
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { getSubs } from "./helper/userapicalls";

const UserDashBoard = () => {
  const {
    user: { name, email, role },
    token,
  } = isAuthenticated();

  const [values, setValues] = useState({
    status: "",
    start: "",
    end: "",
    amount: "",
    id: "",
    payment: "",
    error: "",
  });

  const { status, start, end, amount, id, error, payment } = values;

  const preload = () => {
    getSubs(token).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          status: data.status,
          start: data.start,
          end: data.end,
          amount: data.amount,
          id: data.id,
          payment: data.payment,
        });
        // console.log(categories);
      }
    });
  };

  useEffect(() => {
    preload();
  }, []);

  const subsStatus = () => {
    return (
      <>
        {(() => {
          if (status !== "active") {
            return (
              <div className="col-9 text-warning font-weight-bold">
                You don't have any active Subscription. Please Subscribe
              </div>
            );
          }
        })()}
      </>
    );
  };

  const userLeftSide = () => {
    return (
      <div className="card">
        <h4 className="card-header bg-dark text-white ">Profile Navigation</h4>
        <ul className="list-group">
          <li className="list-group-item">
            <Link to="/user/profile" className="nav-link text-success text-center">
              Add/Edit Info
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="/user/myorders" className="nav-link text-success text-center">
              My Orders
            </Link>
          </li>
          {/*<li className="list-group-item">
                        <Link to="/admin/create/product" className="nav-link text-success text-center">Create Products</Link>
                    </li>
                    <li className="list-group-item">
                        <Link to="/admin/products" className="nav-link text-success text-center">Manage Products</Link>
                    </li>
                    <li className="list-group-item">
                        <Link to="/admin/orders" className="nav-link text-success text-center">Manage Orders</Link>
                    </li>   */}
        </ul>
      </div>
    );
  };

  const userRightSide = () => {
    return (
      <div className="card mb-4">
        <h4 className="card-header">Your Info</h4>
        <ul className="list-group">
          <li className="list-group-item">
            <span className="badge badge-danger mr-2">Welcome User</span>
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Name:</span> {name}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Email:</span> {email}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Role:</span> {role}
          </li>
          <hr />
          <p className="font-weight-bold text-center">Subscription Details</p>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Subscription status:</span> {status}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Subscription start date:</span> {start}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Subscription end date:</span> {end}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Subscription amount:</span> {amount}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Subscription id:</span> {id}
          </li>
          <li className="list-group-item">
            <span className="badge badge-success mr-2">Payment id:</span> {payment}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Base title="UserDashBoard page" className="container bg-success p-4 mt-5 mb-5">
      <div className="row">
        {/* <div className="col-3">{userLeftSide()}</div> */}
        <div className="col-9">{subsStatus()}</div>
        <div className="col-9">{userRightSide()}</div>
      </div>
    </Base>
  );
};

export default UserDashBoard;
